<template>
  <div>
    <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
    >

      <!-- Course details tab -->
      <tab-content
          title="Basic Info"
          :before-change="courseValidationForm"
          @click=""
      >
        <validation-observer
            ref="courseRules"
            tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                  label="English Title"
                  label-for="mc-title-en"
              >
                <validation-provider
                    #default="{ errors }"
                    name="English Title"
                    rules="required"
                >
                  <b-form-input
                      v-model="course.title_en"
                      id="mc-title-en"
                      placeholder="English Title"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Arabic Title"
                  label-for="mc-title-ar"
              >
                <b-form-input
                    v-model="course.title_ar"
                    id="mc-title-ar"
                    placeholder="Arabic Title"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="English Description"
                  label-for="mc-description-en"
              >
                <validation-provider
                    #default="{ errors }"
                    name="English Description"
                    rules="required"
                >
                  <b-form-textarea
                      v-model="course.description_en"
                      id="mc-description-en"
                      placeholder="English Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Arabic Description"
                  label-for="mc-description-ar"
              >
                <b-form-textarea
                    v-model="course.description_ar"
                    id="mc-description-ar"
                    placeholder="Arabic Description"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Price ($)"
                  label-for="mc-price"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Price ($)"
                    rules="required|positive|between:1,99999"
                >
                  <cleave
                      id="number"
                      v-model="course.price"
                      class="form-control"
                      :raw="false"
                      :options="mask.number"
                      placeholder="Price"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Course Has Discount"
                  label-for="landmark"
              >
                <b-form-radio
                    v-model="have_discount"
                    class="custom-control-primary"
                    :name="'have_discount'"
                    value="1"
                >
                  Yes
                </b-form-radio>
                <b-form-radio
                    v-model="have_discount"
                    class="custom-control-primary"
                    :name="'have_discount'"
                    value="0"
                >
                  No
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="have_discount == 1">
              <b-form-group
                  label="Price After Discount ($)"
                  label-for="mc-price-after"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Price After Discount ($)"
                    rules="required|positive|between:1,99999"
                >
                  <cleave
                      id="number"
                      v-model="course.price_after_discount"
                      class="form-control"
                      :raw="false"
                      :options="mask.number"
                      placeholder="Price after discount"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Expire Date Type"
                  label-for="mc-expire-date-type"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Expiry Type"
                    rules="required"
                >
                  <v-select
                      v-model="course.expire_date_type"
                      :reduce="val => val.value"
                      :options="expire_date_type_options"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="course.expire_date_type == 'date'">
              <b-form-group
                  label="Expire Date"
                  label-for="mc-expire-date"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Expire Date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="course.expire_date"
                      :date-disabled-fn="dateDisabled"
                      id="mc-expire-date"
                      placeholder="Expire Date"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="course.expire_date_type == 'duration'">
              <b-form-group
                  label="Expire Date"
                  label-for="mc-expire-date"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Expire Date"
                    rules="required"
                >
                  <v-select
                      v-model="course.expire_duration"
                      :options="expire_date_duration_options"
                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Select Speciality"
                  label-for="mc-speciality"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Speciality"
                    rules="required"
                >
                  <v-select
                      v-model="course.speciality_id"
                      :options="specialities_options"
                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <file
                  title="Select Cover Image"
                  :key="rerender_component"
                  :default_place_holder_src="course.image"
                  @file_uploaded="({media})=>{course.cover_image= media.id;course.image = media.url}"
              ></file>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Course Images tab -->
      <tab-content
          title="Images & Categories"
          :before-change="imagesValidationForm"
      >
        <validation-observer
            ref="imageRules"
            tag="form"
        >
          <b-row>
            <template>
              <b-col md="12">
                <file-multiple
                    title="Upload Course Multiple Images"
                    :id="'immul-'"
                    @multi_files_uploaded="setCourseImages"
                    @multi_files_deleted_file="setCourseImagesDeleteImage"
                ></file-multiple>
              </b-col>
            </template>
          </b-row>
        </validation-observer>
        <br>
        <br>
        <validation-observer
            ref="categoryRules"
            tag="form"
        >
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h5 class="mb-0">
                Course Categories
              </h5>
            </b-col>
            <template v-for="(single_cat, index) in categories">
              <b-col md="5">
                <b-form-group
                    :label="'English Title For Category #'+(index+1)"
                    :label-for="'category-english-title'+(index+1)"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="'English Title #'+(index+1)"
                      rules="required"
                  >
                    <b-form-input
                        :id="'category-english-title'+(index+1)"
                        v-model="single_cat.title_en"
                        placeholder="English Title"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                    :label="'Arabic Title For Category #'+(index+1)"
                    :label-for="'category-arabic-title'+(index+1)"
                >
                  <b-form-input
                      :id="'category-arabic-title'+(index+1)"
                      v-model="single_cat.title_ar"
                      placeholder="Arabic Title"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="2"
                  md="3"
                  class="mb-50"
              >
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeCategory(index)"
                >
                  <feather-icon
                      icon="XIcon"
                      class="mr-25"
                  />
                  <span>Delete</span>
                </b-button>
              </b-col>
              <template v-for="(sub, sub_index) in single_cat.subs">
                <b-col md="4" style="margin-left: 5%">
                  <b-form-group
                      :label="'English Title For Sub Category #'+(sub_index+1)"
                      :label-for="'category-english-sub-title'+(sub_index+1)"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="'English Sub Category Title #'+(sub_index+1)"
                        rules="required"
                    >
                      <b-form-input
                          :id="'category-english-sub-title'+(sub_index+1)"
                          v-model="sub.title_en"
                          placeholder="English Sub Title"
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      :label="'Arabic Title For Sub Category #'+(sub_index+1)"
                      :label-for="'category-arabic-sub-title'+(index+1)"
                  >
                    <b-form-input
                        :id="'category-arabic-sub-title'+(sub_index+1)"
                        v-model="sub.title_ar"
                        placeholder="Arabic Sub Title"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                >
                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeSubCategory(index,sub_index)"
                  >
                    <feather-icon
                        icon="XIcon"
                        class="mr-25"
                    />
                    <span>Delete Sub</span>
                  </b-button>
                </b-col>
              </template>
              <b-col md="12">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                    @click="incrementSubs(index)"
                >
                  Add Sub Category
                </b-button>
                <br><br>
              </b-col>
            </template>
            <br><br>
            <br><br>
            <b-col md="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
                  @click="incrementCategories"
              >
                Add more categories
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Lectures -->
      <tab-content
          title="Lectures"
          :before-change="lecturesValidationForm"
      >
        <validation-observer
            ref="lecturesRules"
            tag="form"
        >
          <b-row>
            <template v-for="(lecture,index) in lectures">
              <b-col md="6">
                <b-form-group
                    label="English Title"
                    label-for="mc-title-en"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="English Title"
                      rules="required"
                  >
                    <b-form-input
                        v-model="lecture.title_en"
                        id="mc-title-en"
                        placeholder="English Title"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Arabic Title"
                    label-for="mc-title-ar"
                >
                  <b-form-input
                      v-model="lecture.title_ar"
                      id="mc-title-ar"
                      placeholder="Arabic Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="English Description"
                    label-for="mc-description-en"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="English Description"
                      rules="required"
                  >
                    <b-form-textarea
                        v-model="lecture.description_en"
                        id="mc-description-en"
                        placeholder="English Description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Arabic Description"
                    label-for="mc-description-ar"
                >
                  <b-form-textarea
                      v-model="lecture.description_ar"
                      id="mc-description-ar"
                      placeholder="Arabic Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Select Category"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Category"
                      rules="required"
                  >
                    <v-select
                        v-model="lecture.category_id"
                        :options="real_categories"
                        :reduce="val => val.value"
                        :state="errors.length > 0 ? false:null"
                        @input="(val) => categoryChanged(val)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="subCategoryOptions.length > 0">
                <b-form-group
                    label="Select Sub Category"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Sub Category"
                      rules="required"
                  >
                    <v-select
                        v-model="lecture.sub_category_id"
                        :options="subCategoryOptions"
                        :reduce="val => val.value"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    name="Video Type"
                    rules="required"
                >
                  <b-form-group
                    label="Select Video Type"
                    label-for="mc-is-active"
                >
                  <v-select
                      v-model="lecture.video_type"
                      :reduce="val => val.value"
                      :options="video_type_options"
                      :state="errors.length > 0 ? false:null"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-if="lecture.video_type == 'upload'" md="6">
                <b-form-group
                    label="Select Lecture Video"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Lecture Video"
                      rules="required"
                  >
                    <b-form-file
                        v-model="lecture.file"
                        :disabled="show"
                        :id="'wildcard-video'+index"
                        accept="video/mp4,video/x-m4v,video/*"
                        @change="uploadVideoToVimeo($event, index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-overlay
                    :show="show"
                    no-wrap
                />
              </b-col>
              <b-col v-if="lecture.video_type == 'url'" md="6">
                <b-form-group
                    label="Select Lecture Video"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Lecture Video"
                      rules="required|url"
                  >
                    <b-form-input
                        :id="'url-video'+index"
                        v-model="lecture.path"
                        :state="errors.length > 0 ? false:null"
                        type="url"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Is Free Content"
                    label-for="landmark"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Is Free Content"
                      rules="required"
                  >
                  <b-form-radio
                      v-model="lecture.is_free_content"
                      class="custom-control-primary"
                      :name="'is_free_content_lectures'+index"
                      :state="errors.length > 0 ? false:null"
                      value="1"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      v-model="lecture.is_free_content"
                      class="custom-control-primary"
                      :name="'is_free_content_lectures'+index"
                      :state="errors.length > 0 ? false:null"
                      value="0"
                  >
                    No
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <file
                    title="Select Video Thumbnail"
                    @thumbnail_uploaded="setLectureImage"
                    :index="index"
                    :key="rerender_component2"
                    :default_place_holder_src="lecture.src"
                ></file>
              </b-col>
              <b-col md="12"></b-col>
              <b-row></b-row>
              <b-col
                  lg="12"
                  md="12"
                  class="mb-50"
              >
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    style="margin-top: 10px"
                    class="mt-0 mt-md-2"
                    @click="removeLecture(index)"
                >
                  <feather-icon
                      icon="XIcon"
                      class="mr-25"
                  />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </template>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  style="margin-top: 10px"
                  variant="primary"
                  class="mr-1"
                  @click="incrementLectures"
              >
                Add More Lectures
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Notes -->
      <tab-content
          title="Notes"
          :before-change="notesValidationForm"
      >
        <validation-observer
            ref="notesRules"
            tag="form"
        >
          <div>
            <template v-for="(note,index) in notes">
              <b-row :index="index">
                <b-col md="6">
                  <b-form-group
                      label="English Title"
                      label-for="mc-title-en"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="English Title"
                        rules="required"
                    >
                      <b-form-input
                          v-model="note.title_en"
                          id="mc-title-en"
                          placeholder="English Title"
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Arabic Title"
                      label-for="mc-title-ar"
                  >
                    <b-form-input
                        v-model="note.title_ar"
                        id="mc-title-ar"
                        placeholder="Arabic Title"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Select Category"
                      label-for="mc-is-active"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Category"
                        rules="required"
                    >
                      <v-select
                          v-model="note.category_id"
                          :options="real_categories"
                          :reduce="val => val.value"
                          :state="errors.length > 0 ? false:null"
                          @input="(val) => categoryChanged(val)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="subCategoryOptions.length > 0">
                  <b-form-group
                      label="Select Sub Category"
                      label-for="mc-is-active"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Sub Category"
                        rules="required"
                    >
                      <v-select
                          v-model="note.sub_category_id"
                          :options="subCategoryOptions"
                          :reduce="val => val.value"
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Choose PDF Type"
                      :label-for="'pdf_type'+index"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="PDF Type"
                        rules="required"
                    >
                      <b-form-radio
                          v-model="note.pdf_type"
                          class="custom-control-primary"
                          :name="'pdf_type'+index"
                          :state="errors.length > 0 ? false:null"
                          value="1"
                      >
                        Upload File
                      </b-form-radio>
                      <b-form-radio
                          v-model="note.pdf_type"
                          class="custom-control-primary"
                          :name="'pdf_type'+index"
                          :state="errors.length > 0 ? false:null"
                          value="2"
                      >
                        Insert URL
                      </b-form-radio>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="note.pdf_type == 1">
                  <b-form-group
                      label="Select PDF File"
                      label-for="mc-is-active"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="PDF File"
                        rules="required"
                    >
                      <b-form-file
                          v-model="note.file"
                          :id="'wildcard'+index"
                          accept="application/pdf"
                          @change="uploadPdf($event, index)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="note.pdf_type == 2">
                  <b-form-group
                      label="Enter PDF URL"
                      label-for="mc-is-active"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="PDF URL"
                        rules="required"
                    >
                      <b-form-input
                          v-model="note.path"
                          type="url"
                          :id="'wildcard'+index"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                      label="Is Free Content"
                      label-for="landmark"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Is Free Content"
                      rules="required"
                  >
                    <b-form-radio
                        v-model="note.is_free_content"
                        class="custom-control-primary"
                        :name="'is_free_content_notes'+index"
                        :state="errors.length > 0 ? false:null"
                        value="1"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="note.is_free_content"
                        class="custom-control-primary"
                        :name="'is_free_content_notes'+index"
                        :state="errors.length > 0 ? false:null"
                        value="0"
                    >
                      No
                    </b-form-radio>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12"></b-col>
                <b-overlay
                    :show="show"
                    no-wrap
                />
                <b-row></b-row>
                <b-col
                    lg="12"
                    md="12"
                    class="mb-50"
                >
                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      style="margin-top: 0!important;"
                      @click="removeNote(index)"
                  >
                    <feather-icon
                        icon="XIcon"
                        class="mr-25"
                    />
                    <span>Remove Note</span>
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </div>
          <b-row>
            <b-col>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
                  @click="incrementNotes"
              >
                Add More Notes
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Questions & Answers  -->
      <tab-content
          title="Questions & Answers"
          :before-change="questionsValidationForm"
      >
        <validation-observer
            ref="questionsRules"
            tag="form"
        >
          <b-row>
            <b-overlay
                :show="show"
                no-wrap
            />
            <template v-for="(question, index) in questions">
              <!--      English Title        -->
              <b-col md="6">
                <b-form-group
                    label="English Question Title"
                    label-for="front-en"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="English Question Title"
                      rules="required"
                  >
                    <b-form-input
                        id="front-en"
                        v-model="question.title_en"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--      Arabic Title        -->
              <b-col md="6">
                <b-form-group
                    label="Arabic Question Title"
                    label-for="front-ar"
                >
                  <b-form-input
                      id="front-ar"
                      v-model="question.title_ar"
                      type="text"
                  />
                </b-form-group>
              </b-col>
              <!--      Category        -->
              <b-col md="6">
                <b-form-group
                    label="Select Category"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Category"
                      rules="required"
                  >
                    <v-select
                        v-model="question.category_id"
                        :options="real_categories"
                        :reduce="val => val.value"
                        :state="errors.length > 0 ? false:null"
                        @input="(val) => categoryChanged(val)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="subCategoryOptions.length > 0">
                <b-form-group
                    label="Select Sub Category"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Sub Category"
                      rules="required"
                  >
                    <v-select
                        v-model="question.sub_category_id"
                        :options="subCategoryOptions"
                        :reduce="val => val.value"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--      Is Free Content        -->
              <b-col md="6">
                <b-form-group
                    label="Is Free Content"
                    label-for="landmark"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Is Free Content"
                      rules="required"
                  >
                  <b-form-radio
                      v-model="question.is_free_content"
                      class="custom-control-primary"
                      :name="'question_is_free_content'+index"
                      :state="errors.length > 0 ? false:null"
                      value="1"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      v-model="question.is_free_content"
                      class="custom-control-primary"
                      :name="'question_is_free_content'+index"
                      :state="errors.length > 0 ? false:null"
                      value="0"
                  >
                    No
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--      Explanation Voice       -->
              <b-col md="6">
                <b-form-group
                    label="Choose Explanation Audio Type"
                    label-for="landmark"
                >
                  <b-form-radio
                      v-model="question.explanation.voice_type"
                      class="custom-control-primary"
                      :name="'explanation_audio_'+index"
                      value="1"
                  >
                    Upload Mp3 File
                  </b-form-radio>
                  <b-form-radio
                      v-model="question.explanation.voice_type"
                      class="custom-control-primary"
                      :name="'explanation_audio_'+index"
                      value="2"
                  >
                    Record Voice
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="question.explanation.voice_type == 1">
                <b-form-group
                    label="Select Audio File"
                    label-for="mc-is-active"
                >
                  <b-form-file
                      v-model="question.explanation.voice"
                      :id="'audio'+index"
                      accept=".mp3,audio/*"
                      @change="uploadExplanationAudio($event, index)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="question.explanation.voice_type == 2">
                <b-form-group
                    label="Record Audio"
                    label-for="mc-is-active"
                >
                  <audio-recorder
                      upload-url="notes/upload-pdf"
                      :attempts="1"
                      :time="2"
                      :before-recording="callback"
                      :pause-recording="recordingPaused"
                      :after-recording="afterRecording"
                      :select-record="callback"
                      :before-upload="callback"
                      :successful-upload="successfulUpload"
                      :failed-upload="failedUpload"/>
                </b-form-group>
              </b-col>
              <!--      Explanation Image        -->
              <b-col md="6">
                <b-form-group
                    label="Select Explanation Image"
                    label-for="mc-is-active"
                >
                  <file
                      v-model="question.explanation.image"
                      :id="'explanation-image'+index"
                      accept="image/*"
                      :default_place_holder_src="question.explanation.src"
                      @file_uploaded="({media})=>{question.explanation.src= media.url;question.explanation.image = media.id}"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label="English Explanation"
                    label-for="mc-explanation-en"
                >
                  <ckeditor :editor="editor" style="height: 200px" :id="'en'" rows="10" v-model="question.explanation.explanation_en"></ckeditor>
                </b-form-group>
              </b-col>
              <br>
              <b-col md="12" style="margin-top: 70px">
                <b-form-group
                    label="Arabic Explanation"
                    label-for="mc-explanation-ar"
                >
                  <ckeditor :editor="editor" style="height: 200px" :id="'en'" rows="10" v-model="question.explanation.explanation_en"></ckeditor>
                </b-form-group>
              </b-col>
              <b-col md="12" style="margin-top: 70px">
                <file
                    title="Select Question Image"
                    :default_place_holder_src="question.src"
                    v-model="question.src"
                    :id="'question-image'+index"
                    accept="image/*"
                    @file_uploaded="({media})=>{question.src= media.url;question.image = media.id}"
                ></file>
              </b-col>
              <b-col md="12" style="margin-top: 20px">
                <h3>Answers</h3>
              </b-col>
              <!--      English Answer        -->
              <template v-for="(answer, answerIndex) in question.answers">
                <b-col md="6">
                  <b-form-group
                      label="English Answer"
                      label-for="front-en"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="English Answer"
                        rules="required"
                    >
                      <b-form-input
                          id="front-en"
                          v-model="answer.answer_en"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Arabic Answer"
                      label-for="front-en"
                  >
                    <b-form-input
                        id="front-en"
                        v-model="answer.answer_ar"
                        type="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Is Correct Answer"
                      label-for="landmark"
                  >
                    <b-form-radio
                        @change="answerChanged(index,answerIndex,1)"
                        v-model="answer.is_correct"
                        class="custom-control-primary"
                        :name="'is_correct'+index+answerIndex"
                        value="1"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        @change="answerChanged(index,answerIndex,0)"
                        v-model="answer.is_correct"
                        class="custom-control-primary"
                        :name="'is_correct'+index+answerIndex"
                        value="0"
                    >
                      No
                    </b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      style="margin-top: 10px"
                      class="mt-0 mt-md-2"
                      @click="removeAnswer(index,answerIndex)"
                  >
                    <feather-icon
                        icon="XIcon"
                        class="mr-25"
                    />
                    <span>Delete Answer</span>
                  </b-button>
                </b-col>
              </template>
                <b-col md="12"></b-col>
                <b-col
                    lg="12"
                    md="12"
                    class="mb-50"
                >
                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="primary"
                      style="margin-top: 10px"
                      type="button"
                      class="mt-0 mt-md-2"
                      @click="addAnswer(index)"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Add Answer</span>
                  </b-button>
                </b-col>
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    style="margin-top: 10px"
                    class="mt-0 mt-md-2"
                    @click="removeQuestion(index)"
                >
                  <feather-icon
                      icon="XIcon"
                      class="mr-25"
                  />
                  <span>Delete</span>
                </b-button>
              <br/><br/>
              </template>
          </b-row>
          <br>
          <br>
          <b-row>
            <b-col>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
                  @click="incrementQuestions"
              >
                Add more Questions & Answers
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Flashcards  -->
      <tab-content
          title="Flash Cards"
          :before-change="flashCardsValidationForm"
      >
        <validation-observer
            ref="flashcardsRules"
            tag="form"
        >
          <b-row>
            <template v-for="(flashcard, index) in flashcards">
              <b-col md="6">
                <b-form-group
                    label="Select Category"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Category"
                      rules="required"
                  >
                    <v-select
                        v-model="flashcard.category_id"
                        :options="real_categories"
                        :reduce="val => val.value"
                        :state="errors.length > 0 ? false:null"
                        @input="(val) => categoryChanged(val)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="subCategoryOptions.length > 0">
                <b-form-group
                    label="Select Sub Category"
                    label-for="mc-is-active"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Sub Category"
                      rules="required"
                  >
                    <v-select
                        v-model="flashcard.sub_category_id"
                        :options="subCategoryOptions"
                        :reduce="val => val.value"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="English Front"
                    label-for="front-en"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="English Front"
                      rules="required"
                  >
                    <b-form-input
                        id="front-en"
                        v-model="flashcard.front_en"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Arabic Front"
                    label-for="front-ar"
                >
                  <b-form-input
                      id="front-ar"
                      v-model="flashcard.front_ar"
                      type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="English Back"
                    label-for="back-en"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="English Back"
                      rules="required"
                  >
                    <b-form-input
                        id="front-en"
                        v-model="flashcard.back_en"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Arabic Back"
                    label-for="back-ar"
                >
                  <b-form-input
                      id="front-ar"
                      v-model="flashcard.back_ar"
                      type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Is Free Content"
                    label-for="landmark"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Is Free Content"
                      rules="required"
                  >
                  <b-form-radio
                      v-model="flashcard.is_free_content"
                      class="custom-control-primary"
                      :name="'is_free_content'+index"
                      :state="errors.length > 0 ? false:null"
                      value="1"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      v-model="flashcard.is_free_content"
                      class="custom-control-primary"
                      :name="'is_free_content'+index"
                      :state="errors.length > 0 ? false:null"
                      value="0"
                  >
                    No
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12"></b-col>
              <b-col
                  lg="12"
                  md="12"
                  class="mb-50"
              >
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    style="margin-top: 10px"
                    class="mt-0 mt-md-2"
                    @click="removeFlashCard(index)"
                >
                  <feather-icon
                      icon="XIcon"
                      class="mr-25"
                  />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </template>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
                  @click="incrementFlashcards"
              >
                Add more Flashcards
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
  BOverlay,
  BFormInvalidFeedback, BFormRadio, BFormFile,
} from 'bootstrap-vue'
import {AudioRecorder} from 'vue-audio-recorder-no-eventbus'
import { required, email } from '@validations'
import { codeIconInfo } from './code'
import {codeIcon} from "@/views/forms/form-wizard/code";
import { codeNoWrapMode } from './overlay_code'
import {ref} from "@vue/composition-api/dist/vue-composition-api";
import ClassicEditor from '../../../../ckeditor5-custom';

export default {
  components: {
    BOverlay,
    Cleave,
    ClassicEditor,
    AudioRecorder,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormFile,
    vSelect,
    BFormInvalidFeedback,
    BCardCode,
    BFormDatepicker,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup(){
    const snowOption = ref({
      theme: 'snow',
    })
    return {
      snowOption,
    }
  },
  props: ['default_place_holder_src'],
  data() {
    return {
      editor: ClassicEditor,
      image_default : this.default_place_holder_src,
      have_discount: 0,
      mask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'wan',
          delimiter: '',
          numericOnly: true,
        },
      },
      show: false,
      title: "Course",
      video_type_options: [
          {
            label : "Upload video",
            value: "upload"
          },
        {
          label : "Insert a URL",
          value: "url"
        }
      ],
      expire_date_type_options: [
          {
            label : "Fixed Duration",
            value: "duration"
          },
        {
          label : "Date",
          value: "date"
        }
      ],
      expire_date_duration_options: [
          {
            label : "1 Month",
            value: "1 Month"
          },
        {
          label : "3 Months",
          value: "3 Months"
        },
        {
          label : "6 Months",
          value: "6 Months"
        }
      ],
      selected_speciality: null,
      specialities_options: [],
      lists: [],
      module_be_base_url: 'courses',
      rerender_component:0,
      rerender_component2:0,
      rerender_component3:0,
      rerender_component4:0,
      course: {
        title_en: null,
        title_ar: null,
        is_active: true,
        speciality_id: null,
        description_en: null,
        description_ar: null,
        price: null,
        expire_date: null,
        expire_duration: null,
        expire_date_type: null,
        id: null,
        image: null,
        price_after_discount: null
      },
      categories: [{
        title_en: null,
        title_ar: null,
        subs: []
      }],
      real_categories: [{
        title_en: null,
        title_ar: null,
        subs: []
      }],
      flashcards: [{
        category_id: null,
        sub_category_id: null,
        front_en: null,
        front_ar: null,
        back_en: null,
        back_ar: null,
        is_free_content: null
      }],
      questions: [{
        title_en: null,
        title_ar: null,
        category_id: null,
        sub_category_id: null,
        image: null,
        src: null,
        image_id: null,
        is_free_content: null,
        explanation:{
          image: null,
          src: null,
          voice_type: null,
          voice: null,
          explanation_en:null,
          explanation_ar:null,
          image_path: null,
          voice_path: null
        },
        answers: [
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: '',
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: '',
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: '',
            is_correct: null
          }
        ]
      }],
      images: [],
      notes: [{
        category_id: null,
        sub_category_id: null,
        title_en: null,
        pdf_type: null,
        title_ar: null,
        is_free_content: null,
        file: null,
        path: null,
      }],
      lectures: [{
        video_type: null,
        category_id: null,
        sub_category_id: null,
        title_en: null,
        title_ar: null,
        description_en: null,
        description_ar: null,
        thumb: null,
        src:null,
        is_free_content: null,
        file: null,
        path: null,
      }],
      codeIconInfo,
      subCategoryOptions: [],
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
    }
  },
  created() {
    this.$http.get('all-specialities')
        .then(res => {
          if (res.data.status_code == 1){
            let that = this
            this.lists = res.data.data.map(function(item){
              that.specialities_options.push({
                label : item.title_en,
                value: item.id
              })
            })
          }
        })
  },
  methods: {
    categoryChanged(val){
      this.$http.get('/sub-categories?'+'course_id='+this.course.id+'&category_id='+val).then(res => {
        let that = this
        this.lists.splice(0)
        this.subCategoryOptions.splice(0)
        this.lists = res.data.data.map(function(item){
          that.subCategoryOptions.push({
            label : item.label,
            value: item.value+""
          })
        })
      }).catch(e => {
        reject()
      })
    },
    addAnswer(question_index){
      this.questions[question_index].answers.push(
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
      )
    },
    removeAnswer(question_index, answer_index){
      this.questions[question_index].answers.splice(answer_index, 1)
    },
    callback (data) {
      console.log(data)
      console.debug(data)
    },
    recordingPaused(data){
      console.log('finished' + data)
    },
    afterRecording(data){
      let form = new FormData();
      this.show = true
      form.append('name', 'my-pdf');
      form.append('file', data.blob);

      let config = {
        header: {
          'Content-Type': 'audio/mp3'
        }
      }
      this.$http.post('notes/upload-pdf',
          form,
          config
      ).then(response => {
        this.questions[this.questions.length-1]['explanation']['voice_path'] = response.data.path
        this.show = false
      })
      // console.log(data.blob)
    },
    successfulUpload(data){
      console.log('succ')
    },
    failedUpload(data){
      console.log('failed')
    },
    answerChanged(questionIndex,answerIndex,value){
      this.questions[questionIndex].answers.forEach(radio => {
        if (value == "1"){
          radio.is_correct = "0"
          this.questions[questionIndex].answers[answerIndex].is_correct = "1"
        }else{
          radio.is_correct = "1"
          this.questions[questionIndex].answers[answerIndex].is_correct = "0"
        }
      })
    },
    // voiceTypeChanged(index,value){
    //   this.questions[index].explanation.forEach(radio => {
    //     if (value == "1"){
    //       radio.is_correct = "0"
    //       this.questions[questionIndex].answers[answerIndex].is_correct = "1"
    //     }else{
    //       radio.is_correct = "1"
    //       this.questions[questionIndex].answers[answerIndex].is_correct = "0"
    //     }
    //   })
    // },
    showAlert(subject, property){
      subject[property] = subject[property].value
    },
    showAlertWithIndex(subject, property,index){
      subject[property] = subject[property].value
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      const month = date.getMonth();
      const year = date.getFullYear();

      const current = new Date();
      const currentDay = current.getDate()
      const currentMonth = current.getMonth()
      const currentYear = current.getFullYear()

      // Return `true` if the date should be disabled
      if (year < currentYear){
        return true
      }
      if (year <= currentYear){
        if (month <= currentMonth){
          if (day <= currentDay && year <= currentYear){
            return true
          }else if (day >= currentDay && month < currentMonth){
            return true
          }
        }
      }
    },
    removeImage(index) {
      this.images.splice(index, 1)
    },
    removeNote(index) {
      this.notes.splice(index, 1)
    },
    removeCategory(index) {
      this.categories.splice(index, 1)
    },
    removeSubCategory(index,sub_index) {
      this.categories[index].subs.splice(sub_index, 1)
    },
    removeLecture(index) {
      this.lectures.splice(index, 1)
    },
    removeQuestion(index) {
      this.questions.splice(index, 1)
    },
    removeFlashCard(index) {
      this.flashcards.splice(index, 1)
    },
    uploadPdf(event, index){
      this.show = true
      let data = new FormData();
      data.append('name', 'my-pdf');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('notes/upload-pdf',
          data,
          config
      ).then(response => {
        this.show = false
        this.notes[index]['path'] = response.data.path
      })
    },
    uploadExplanationAudio(event, index){
      let data = new FormData();
      data.append('name', 'my-audio');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('notes/upload-pdf',
          data,
          config
      ).then(response => {
        this.questions[index]['explanation']['voice_path'] = response.data.path
      })
    },
    uploadVideoToVimeo(event, index){
      this.show = true
      let data = new FormData();
      data.append('name', 'my-pdf');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('lectures/upload-video',
          data,
          config
      ).then(response => {
        this.lectures[index]['path'] = response.data.url
        this.show = false
      })
    },
    incrementCategories(){
      this.categories.push({
        title_en: '',
        title_ar: '',
        subs: []
      })
    },
    incrementSubs(category_id){
      this.categories[category_id].subs.push({
        title_en: '',
        title_ar: ''
      })
    },
    incrementFlashcards(){
      this.flashcards.push({
        category_id: null,
        sub_category_id: null,
        front_en: null,
        front_ar: null,
        back_en: null,
        back_ar: null,
        is_free_content: null
      })
    },
    incrementNotes(){
      this.notes.push({
        category_id: null,
        sub_category_id: null,
        pdf_type: null,
        is_free_content: null,
        file: null
      })
    },
    incrementImages(){
      this.images.push({
        image: null,
        src: null,
        render: 0
      })
    },
    incrementLectures(){
      this.lectures.push({
        video_type: null,
        category_id: null,
        sub_category_id: null,
        title_en: null,
        title_ar: null,
        description_en: null,
        description_ar: null,
        thumb: null,
        src:null,
        is_free_content: null,
        file: null,
        path: null,
      })
    },
    incrementQuestions(){
      this.questions.push({
        title_en: null,
        title_ar: null,
        category_id: null,
        sub_category_id: null,
        image: null,
        src: null,
        image_id: null,
        explanation:{
          image: null,
          voice: null,
          explanation_en:null,
          explanation_ar:null,
          image_path: null,
          voice_path: null
        },
        is_free_content: null,
        answers: [
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
        ]
      })
    },
    formSubmitted() {
      this.$http.post('/flashcards', {
        cards: this.flashcards,
        course_id: this.course.id
      }).then(response => {
        this.$swal({
          title: 'Success!',
          html: 'Course data added successfully.',
          timer: 3000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }, function (){
          this.$router.push('dashboard-courses-list')
        })
      }).catch(e => {
        reject()
      })
      this.$router.push({name : 'dashboard-courses-list'})
    },
    showToast(variant, position, message) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: message,
              variant,
            },
          },
          {
            position,
          })
    },
    setImage(imageId) {
      this.course.cover_image = imageId
    },
    setQuestionImage(data) {
      this.questions[data.index]['image_id'] = data.id
      this.questions[data.index]['src'] = data.src
    },
    setLectureImage(data) {
      this.lectures[data.index]['thumb'] = data.id
      this.lectures[data.index]['src'] = data.src
    },
    setCourseImages(data){
      data.media.forEach(im => {
        this.images.push(im.id)
      })
      // this.images[data.index]['image'] = data.id
      // this.images[data.index]['src'] = data.src
    },
    setCourseImagesDeleteImage(data){
      this.images.splice(data.index, 1)
    },
    setExplanationImages(data){
      this.questions[data.index]['explanation']['image'] = data.id
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    courseValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.courseRules.validate().then(success => {
          if (success) {
            let flag = 1;
            let price = parseFloat(this.course.price)
            let price_after = parseFloat(this.course.price_after_discount)
            let cover = this.course.cover_image
            if (price_after >= price){
              console.log(price+" "+price_after)
              this.showToast('error', 'top-center', "Price after discount can't be greater than or equal to price")
              flag = 0;
              reject()
            }
            if (cover < 1){
              this.showToast('error', 'top-center', "Please select a cover image")
              flag = 0
              reject()
            }
            if (flag == 1){
              this.$http.post('/courses', this.course).then(response => {
                this.course = {
                  title_en: null,
                  title_ar: null,
                  is_active: true,
                  speciality_id: null,
                  description_en: null,
                  description_ar: null,
                  price: null,
                  expire_date: null,
                  expire_duration: null,
                  expire_date_type: null,
                  image: null,
                  price_after_discount: null
                }
                this.course.id = response.data.data.id
                this.showToast('success', 'top-center', response.data.message)
                resolve(true)
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Please fill all required fields including cover image.',
                    icon: 'EditIcon',
                    position: 'top-center',
                    variant: 'error',
                  },
                }, {
                  position: 'top-center'
                })
                reject()
              })
            }

          } else {
            reject()
          }
        })
      })
    },
    questionsValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.questionsRules.validate().then(success => {
          if (success) {
            this.$http.post('questions-and-answers', {
              questions: this.questions,
              course_id: this.course.id
            }).then(response => {
              this.questions = [{
                title_en: null,
                title_ar: null,
                category_id: null,
                sub_category_id: null,
                image: null,
                src: null,
                image_id: null,
                is_free_content: null,
                explanation:{
                  image: null,
                  src: null,
                  voice_type: null,
                  voice: null,
                  explanation_en:null,
                  explanation_ar:null,
                  image_path: null,
                  voice_path: null
                },
                answers: [
                  {
                    answer_en: null,
                    answer_ar: null,
                    is_correct: null
                  },
                  {
                    answer_en: null,
                    answer_ar: null,
                    is_correct: null
                  },
                  {
                    answer_en: null,
                    answer_ar: null,
                    is_correct: null
                  },
                  {
                    answer_en: null,
                    answer_ar: null,
                    is_correct: null
                  },
                  {
                    answer_en: null,
                    answer_ar: null,
                    is_correct: null
                  },
                ]
              }]
              this.subCategoryOptions.splice(0)
              this.showToast('success', 'top-center', response.data.message)
            })
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    lecturesValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.lecturesRules.validate().then(success => {
          if (success) {
            this.lectures.forEach(thumb => {
              if (thumb['thumb'] == '' || thumb['thumb'] == 'undefined' || thumb['thumb'] == null){
                this.showToast('error', 'top-center', "Please select thumb")
                reject()
              }else {
                this.$http.post('lectures', {
                  lectures: this.lectures,
                  course_id: this.course.id
                }).then(response => {
                  this.lectures = [{
                    video_type: null,
                    category_id: null,
                    sub_category_id: null,
                    title_en: null,
                    title_ar: null,
                    description_en: null,
                    description_ar: null,
                    thumb: null,
                    src:null,
                    is_free_content: null,
                    file: null,
                    path: null,
                  }]
                  this.showToast('success', 'top-center', response.data.message)
                  resolve(true)
                  this.subCategoryOptions.splice(0)
                }).catch(e => {
                  reject()
                })
              }
            })
          } else {
            reject()
          }
        })
      })
    },
    imagesValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.imageRules.validate().then(success => {
          if (success) {
            this.$refs.categoryRules.validate().then(successCat => {
              if (successCat){
                this.$http.post('course-images', {
                  images: this.images,
                  course_id: this.course.id
                }).then(response => {
                  this.$http.post('/courses/store-categories', {
                    categories: this.categories,
                    course_id: this.course.id
                  }).then(response => {
                    this.$http.get('categories?course_id='+this.course.id).then(res => {
                      this.categories = [{
                        title_en: null,
                        title_ar: null,
                        subs: []
                      }]
                      this.real_categories = res.data.data
                    }).catch(e => {
                      reject()
                    })
                    this.images.splice(0)
                    this.$swal({
                      title: 'Image and categories created Successfully',
                      text: "Do you want to continue adding course data or do it later ?",
                      icon: 'success',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, continue',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (!result.value) {
                        this.$router.push({name: 'dashboard-courses-list'})
                      }
                    })
                    resolve(true)
                  })
                })
              }else{
                reject()
              }
            })

          } else {
            reject()
          }
        })
      })
    },
    flashCardsValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.flashcardsRules.validate().then(success => {
          if (success) {
            this.subCategoryOptions.splice(0)
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    categoriesValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.categoryRules.validate().then(success => {
          if (success) {
            this.$http.post('/courses/store-categories', {
              categories: this.categories,
              course_id: this.course.id
            }).then(response => {
              this.$http.get('categories?course_id='+this.course.id).then(res => {
                this.categories = res.data.data
              }).catch(e => {
                reject()
              })
              this.showToast('success', 'top-center', response.data.message)
              resolve(true)
            })
          } else {
            reject()
          }
        })
      })
    },
    notesValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.notesRules.validate().then(success => {
          if (success) {
            this.subCategoryOptions.splice(0)
            this.$http.post('/course-notes', {
              notes: this.notes,
              course_id: this.course.id
            }).then(response => {
              this.notes = [{
                category_id: null,
                sub_category_id: null,
                title_en: null,
                pdf_type: null,
                title_ar: null,
                is_free_content: null,
                file: null,
                path: null,
              }]
              this.showToast('success', 'top-center', response.data.message)
              resolve(true)
            }).catch(e => {
              reject()
            })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.square_shape{
  .square_shape{
    background-color: $primary!important;
  }
}
.stepTitle.active{
  color: $primary !important;
}
  .wizard-btn{
    background-color: $primary !important;
    border-color: $primary !important;
  }
  .ar-icon{
    svg{
      margin-top: -10px;
    }
  }
  .ar-content{
    height: 276px;
  }
.ck{
  height: 200px;
}
.ar-player-bar{
  width: 64%!important;
}
.ar-player-actions{
  width: 10%!important;
}
.ar{
  width: 100%!important;
}
.ar-recorder{
  margin-left: -6%;
}
.ar-recorder__duration{
  margin-top: 28px !important;
}
.vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li{
  cursor: not-allowed;
  pointer-events: none;
}
</style>
